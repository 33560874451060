/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Image, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"K O N T A K T"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"kontakt"} style={{"backgroundColor":"rgba(44,45,54,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"16yzjgi321v"} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--16" style={{"color":"var(--color-variable-1)"}} content={"a r c h i t e k t &nbsp; - &nbsp; p r o j e k t a n t"}>
              </Subtitle>

              <Title className="title-box fs--24" content={"I N G . &nbsp;A R C H . &nbsp;<br><br>J A N A &nbsp;L O R E N C O V Á<br>"}>
              </Title>

              <Subtitle className="subtitle-box fs--16" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: var(--color-custom-2);\"><br>I Č O &nbsp;0 7 3 9 8 6 6 2</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box fs--18" content={"l o r e n c o v a j a n a @ g m a i l . c o m"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box" content={"+ 4 2 0 &nbsp;7 2 5 &nbsp;6 4 9 &nbsp;5 2 3"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} RootClassName={"column__icon"}>
              </Image>

              <Subtitle className="subtitle-box fs--18" content={"H l a v á č k o v a &nbsp;3 2 0 5 , 4 4 0 &nbsp;0 1 &nbsp;L o u n y&nbsp;<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}